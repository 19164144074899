import type { MetaFunction } from "@remix-run/node";

type MetaProps = {
    title?: string;
    description?: string;
    keywords?: string;
    image?: string;
    type?: string;
    baseUrl?: string;
    appTitle?: string;
    index?: boolean;
}

export const generateMeta = ({
    title,
    description = "Discover and connect with relevant people at professional events effortlessly with our SaaS tool. Enhance your networking and sales experiences and make meaningful connections with ease.",
    keywords = "Professional events, SDR tool, event networking, find relevant people, event connections, professional networking software, sales research tool",
    image = "https://953697314.imgix.net/landing/people.png?auto=format,enhance",
    type = "website",
    baseUrl = "",
    appTitle = "FindPowerPeople",
    index = true,
}: MetaProps): ReturnType<MetaFunction> => {
    const finalTitle = title ? `${title} | ${appTitle}` : appTitle;

    const tags = [
        { title: finalTitle },
        { name: "description", content: description },
        { name: "keywords", content: keywords },

        // Open Graph
        { property: "og:title", content: finalTitle },
        { property: "og:description", content: description },
        { property: "og:image", content: image },
        { property: "og:url", content: baseUrl },
        { property: "og:type", content: type },

        // Twitter
        { name: "twitter:card", content: "summary_large_image" },
        { name: "twitter:title", content: finalTitle },
        { name: "twitter:description", content: description },
        { name: "twitter:image", content: image },
    ];

    if (index) {
        tags.push({ name: "robots", content: "index,follow" });
    }

    return tags;
}; 